/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/css/bundle';

/* Import fonts needed here */
@import "https://use.typekit.net/moo6iws.css";

@font-face {
    font-family: 'GillSans';
    src: local("GillSans"),url(/assets/fonts/GillSans-01.ttf);
}

@font-face {
    font-family: 'MyriadPro';
    src: url(/assets/fonts/MyriadPro-Regular.otf);
}

@font-face {
    font-family: 'Perfetto';
    src: url(/assets/fonts/Perfetto.ttf);
}

@font-face {
  font-family: 'Champagne';
  src: url(/assets/fonts/SaturdayChampagneDemo-Regular.ttf);
}

@font-face{
  font-family: 'Raleway';
  src: url(/assets/fonts/Raleway-Medium.ttf);
}

ion-app {
  max-width: 390px;
  margin: 0 auto;
}

.message {
  text-align: center;
  font-weight: bold;
  font-family: korolev-compressed, serif;
  font-size: 20px;
  margin: 5px 0;
}

.error {
  color: maroon;
}

.success {
  color: green;
}

.success-notification {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  background: #759773 !important;
  color: white;
  text-align: center;
}

.error-notification {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  background: #A4453E !important;
  color: white;
  text-align: center;
}

hr {
  background: #A7A9AC;
  opacity: .20;
  height: 2px;
}

//Set selected state color of icons
ion-tab-button ion-icon{
  --background-focused: #759773;
  --color-selected: #759773;
}

//Override default padding on buttons to implement on a case-by-case basis
.custom-button {
  background: white;
  border: 1px solid #759773;
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
  min-width: 100px;
  text-align: center;
}

.custom-button:hover {
  cursor: pointer;
}

.filled-button {
  background: #759773 !important;
  color: white !important;
  border: none !important;
}

.vertical-button {
  width: 120px;
  display: block;
  margin: 10px auto;
}

.horizontal-button-left {
  margin: 15px 10px 15px 0;
}

.horizontal-button-right {
  margin: 15px 0 15px 10px;
}

.button-small {
  --padding-start: initial;
  --padding-end: initial;
}

.swiper-pagination-bullet-active, .swiper-pagination-bullet {
  background: white !important;
}

.swiper-pagination-bullet {
  border: .5px white !important;
}

ion-toolbar{
    --background: #002F3E !important;
    height: 150px;
    padding-top: 5%;
}

.discoveryToolBar{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  margin-top: 25px;
}

.back-button {
  position: absolute;
  top: 38%;
}

.user-button {
  position: absolute;
  top: 18% !important;
  right: 10px;
  overflow: hidden;
  max-height: 32px;
}

.user-button-stacked {
  position: absolute;
  top: 25%;
  right: 0;
}

#circle {
  position: absolute;
  top: 15%;
  left: 21px;
  width: 7px;
  height: 7px;
  background: #759773;
  border-radius: 99px;
}

.sc-ion-label-md-s p {
  line-height: 12px;
}

